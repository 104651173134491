<template>
    <div class="card flex justify-between flex-wrap lg:flex-no-wrap">
        <ModalCreateNewInvoice
            v-if="modalCreateInvoiceVisible"
            :modalCreateInvoice="modalCreateInvoice"
            @refresh="emitRefresh"
            @close="modalCreateInvoice = false"
            @closed="modalCreateInvoiceVisible = false"
        />

        <div class="flex">
            <InvoiceTypesDropdown
                @change="emitRefresh"
            />
            <ClientsDropdown
                withArchive
                multiple
                class="w-64"
                @selected="emitRefresh"
            />
        </div>
        <div class="flex flex-wrap md:flex-no-wrap">
            <!-- <ActionDropdown
                @showModalCreateInvoice="showModalCreateInvoice"
                @change="emitRefresh"
            /> -->
            <ChangeStatusDropdown @change="emitRefresh" />
            <DateRangePicker @change="emitRefresh" />
        </div>
    </div>
</template>
<script>
export default {
    components: {
        ClientsDropdown:       () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
        DateRangePicker:       () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
        ActionDropdown:        () => import(/* webpackChunkName: "TopCardMenu/ActionDropdown" */ './ActionDropdown'),
        ChangeStatusDropdown:  () => import(/* webpackChunkName: "TopCardMenu/ChangeStatusDropdown" */ './ChangeStatusDropdown'),
        InvoiceTypesDropdown:  () => import(/* webpackChunkName: "TopCardMenu/InvoiceTypesDropdown" */ './InvoiceTypesDropdown'),
        ModalCreateNewInvoice: () => import(/* webpackChunkName: "TopCardMenu/ModalCreateNewInvoice" */ '../modals/ModalCreateInvoice'),
    },

    data() {
        return {
            modalCreateInvoiceVisible: false,
            modalCreateInvoice:        false,
        };
    },

    methods: {
        clientSelected() {
            this.$emit('refreshAll');
        },

        async showModalCreateInvoice() {
            this.modalCreateInvoiceVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalCreateInvoice = true;
        },

        emitRefresh() {
            this.$emit('refreshAll');
        },
    },
};
</script>
